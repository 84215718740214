import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/teamtransmogrify/transmogrify-website.gitlab.io/src/components/Layout.tsx";
import ImagesGrid from '../components/ImagesGrid';
import Wrapper from '../components/Wrapper';
import SEO from '../components/Seo';
import Logo from '../components/Logo';
import PresskitDownloadButton from '../components/PresskitDownloadButton';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Presskit" mdxType="SEO" />
    <Wrapper padding="4em" mdxType="Wrapper">
      <Logo mdxType="Logo" />
      <PresskitDownloadButton mdxType="PresskitDownloadButton" />
      <h2 {...{
        "id": "what-is-transmogrify",
        "style": {
          "position": "relative"
        }
      }}>{`What is Transmogrify?`}</h2>
      <p>{`An unusual 2D puzzle platformer that blends challenging action and handcrafted puzzles. It’s set in a sci-fi world with comics-like modern graphics, and features a peculiar, non-lethal weapon that transforms enemies into tools and structures needed to solve the levels.`}</p>
      <p>{`It’s a puzzle platformer where you also have to react quick, or a hard action platformer where you also have to think!`}</p>
      <h2 {...{
        "id": "factsheet",
        "style": {
          "position": "relative"
        }
      }}>{`Factsheet`}</h2>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Developer`}</strong>{`: Odyssey Entertainment`}</li>
        <li parentName="ul"><strong parentName="li">{`Release Date`}</strong>{`: TBD`}</li>
        <li parentName="ul"><strong parentName="li">{`Platforms`}</strong>{`: PC (Windows/Mac/Linux)`}</li>
        <li parentName="ul"><strong parentName="li">{`Website`}</strong>{`: `}<a parentName="li" {...{
            "href": "https://www.playtransmogrify.com"
          }}>{`playtransmogrify.com`}</a></li>
        <li parentName="ul"><strong parentName="li">{`Price`}</strong>{`: TBD`}</li>
        <li parentName="ul"><strong parentName="li">{`Availability`}</strong>{`: Digital Download on `}<a parentName="li" {...{
            "href": "https://store.steampowered.com/app/740310/Transmogrify/"
          }}>{`Steam`}</a>{`, `}<a parentName="li" {...{
            "href": "https://odysseyentertainment.itch.io/transmogrify"
          }}>{`Itch`}</a>{`, and Gamejolt`}</li>
        <li parentName="ul"><strong parentName="li">{`Languages`}</strong>{`: English`}</li>
        <li parentName="ul"><strong parentName="li">{`ESRB`}</strong>{`: E for everyone, USK 6+, PEGI 7, OFLC PG`}</li>
        <li parentName="ul"><strong parentName="li">{`File Size`}</strong>{`: ~1.5Gb`}</li>
        <li parentName="ul"><strong parentName="li">{`Press Contact`}</strong>{`: `}<a parentName="li" {...{
            "href": "mailto:contact@onanodyssey.com"
          }}>{`contact@onanodyssey.com`}</a></li>
        <li parentName="ul"><strong parentName="li">{`Social`}</strong>{`: `}<a parentName="li" {...{
            "href": "https://discordapp.com/invite/5AeaZxX"
          }}>{`Discord`}</a>{`, `}<a parentName="li" {...{
            "href": "https://twitter.com/TheTransmogrify"
          }}>{`Twitter`}</a>{`, `}<a parentName="li" {...{
            "href": "https://www.kickstarter.com/projects/952307124/transmogrify?ref=c5vynu"
          }}>{`KickStarter`}</a></li>
      </ul>
      <h2 {...{
        "id": "scope",
        "style": {
          "position": "relative"
        }
      }}>{`Scope`}</h2>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Target Run Length`}</strong>{`: ~ 5 hours, 76 levels total`}</li>
        <li parentName="ul"><strong parentName="li">{`Enemies & Tools`}</strong>{`: 22 enemies + 1 boss with different behaviors. They transform into 12 different tools or power-ups`}</li>
        <li parentName="ul"><strong parentName="li">{`Puzzles`}</strong>{`: Unique puzzles in each environment, with new mechanics introduced along the way`}</li>
        <li parentName="ul"><strong parentName="li">{`Environments`}</strong>{`: 4 unique worlds to traverse`}</li>
        <li parentName="ul"><strong parentName="li">{`Power-ups`}</strong>{`: 11 power-ups that change possibilities and gameplay`}</li>
      </ul>
      <h2 {...{
        "id": "features",
        "style": {
          "position": "relative"
        }
      }}>{`Features`}</h2>
      <ul>
        <li parentName="ul">{`Puzzle platformer that looks like a mix of comic book and sci-fi`}</li>
        <li parentName="ul">{`Over a dozen beautifully crafted enemies that each have their own personality, goals, and corresponding transmogrification, drawn in an interesting “creepy yet cute” style`}</li>
        <li parentName="ul">{`Whereas most puzzle platformers remain mechanically simple, Transmogrify does not shy away from difficult platforming and challenging combat.`}</li>
        <li parentName="ul">{`A unique gameplay dynamic with a non-lethal weapon that transforms foes into blocks, platforms, springs, and more tools to solve puzzles`}</li>
        <li parentName="ul">{`A modern, colorful, comic book meets sci-fi aesthetic `}</li>
        <li parentName="ul">{`A bangin’ synthwave industrial soundtrack `}</li>
      </ul>
      <h2 {...{
        "id": "description",
        "style": {
          "position": "relative"
        }
      }}>{`Description`}</h2>
      <p>{`It all started while you were routinely cleaning storage room 2B, section 6. First the loud sound of a breaker tripping and then…pitch darkness. The facility locked down and emergency lights switched on.`}</p>
      <p>{`After a system reset GRACE, the facility’s research AI, comes to your rescue. She has no memory of the events leading up the failure and informs you the specimens have all gotten free. GRACE urges you to escape and gives you a device only labeled as Project Transmogrify, that turns living matter into usable tools and structures.`}</p>
      <p>{`Was that something skittering in the air vents?`}</p>
      <p>{`Transmogrify is an unusual 2D puzzle platformer that blends challenging action and handcrafted puzzles. You play as Chris, a janitor working at Perfect Future Laboratories. This high-tech and secretive research facility has suffered catastrophic damage. Now your only way out is through several radioactively colorful worlds infested with cute but lethal monsters. Armed only with your gun and the help of GRACE, you must manipulate your environment and figure out way to survive.`}</p>
      <p>{`A fresh take on the idea of a puzzle-platformer, Transmogrify does not shy away from cranking up the difficulty and complexity while still delivering a streamlined gameplay experience.`}</p>
      <p>{`Over 75 levels are packed with fast-paced action, tons of enemies and powerups, all set to an exciting soundtrack. The player’s ability to change their environment through transmogrifying monsters into blocks, springs, floating platforms and other tools is what sets the experience apart.  Smooth controls combine with an innovative mechanic that is as fun as the name Transmogrify makes it sound.`}</p>
      <h2 {...{
        "id": "history",
        "style": {
          "position": "relative"
        }
      }}>{`History`}</h2>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Origin`}</strong>{`: Transmogrify burst to life from a game jam where the theme was “unconventional weapon”. We upped the ante by also creating a weapon that doesn’t kill anything. The idea of a gun that turns enemies into objects to solve puzzles sounded fantastic and we ran with it. `}</li>
        <li parentName="ul"><strong parentName="li">{`Desire`}</strong>{`: We like puzzle games, and we also like fiendishly difficult platformers. There aren’t enough games out there that combine both in a satisfying manner. Puzzlers have simple controls with less action, whereas difficult platformers are usually action-packed and don’t ask you to problem-solve as much. We knew there was a happy medium and that the two genres could work very well together.`}</li>
        <li parentName="ul"><strong parentName="li">{`Engine & Community`}</strong>{`: We developed Transmogrify with the open-source Godot Engine. It has been a blast seeing Godot grow and being a part of the super friendly community. We’re open sourcing everything we can to give back`}</li>
        <li parentName="ul"><strong parentName="li">{`Trivia`}</strong>{`: The name of the game was inspired by Calvin and Hobbes, and Calvin’s “transmogrifier” cardboard box that changed him into different creatures.`}</li>
        <li parentName="ul"><strong parentName="li">{`Team & Financing`}</strong>{`: The team started as Andrew & Friends, but grew to include about 4 developers, 2 designers, 2 level designers, 1 sound designer, and 1 writer. The team is paid out of pocket by Andrew`}</li>
      </ul>
      <ImagesGrid mdxType="ImagesGrid" />
      <PresskitDownloadButton mdxType="PresskitDownloadButton" />
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      